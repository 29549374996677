import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default function Cv() {
    return (
        <Layout>
            <SEO title="CV" description="CV for Dylan van den Brink" />
            <h1>CV</h1>
        </Layout>
    )
}